/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { ChildCard } from "./ChildCard";
import { WaveTitle } from "./WaveTitle";
import { BackToMenuButton } from "./BackToMenuButton";

interface HeaderProps {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  onBack?: () => void;
  childInfo?: {
    picture: string;
    name: string;
    urlRedirection?: string;
  };
  titleColor?: "pink" | "blue" | "green" | "black";
  showBackToMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  onBack,
  childInfo,
  titleColor = "blue",
  showBackToMenu = false,
}) => {
  const getTitleColor = () => {
    switch (titleColor) {
      case "pink":
        return "text-pink-400";
      case "blue":
        return "text-blue-600";
      case "green":
        return "text-green-600";
      case "black":
        return "text-gray-800";
      default:
        return "text-gray-800";
    }
  };

  return (
    <header className="w-full mx-auto p-4 md:p-6 rounded-lg shadow-lg bg-gray-50 flex flex-col">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-start">
          <div>
            <WaveTitle
              color={titleColor === "black" ? "blue" : titleColor}
              title=""
              titleColor="black"
            />
            <h1 className={`text-2xl font-bold ${getTitleColor()}`}>{title}</h1>
            {subtitle && (
              <h2 className="text-lg font-light text-gray-500 mt-1">
                {subtitle}
              </h2>
            )}
            {showBackToMenu && (
              <div className="mt-4">
                <BackToMenuButton className="text-blue-600 bg-white border border-blue-300 shadow-md hover:bg-blue-50 transition-transform duration-200 transform hover:scale-105" />
              </div>
            )}
          </div>
        </div>
        {childInfo && (
          <div className="flex flex-col items-center ml-auto">
            <ChildCard
              picture={childInfo.picture}
              name={childInfo.name}
              urlRedirection={childInfo.urlRedirection ?? ""}
              hoverColor="blue-500"
              height="h-40"
              fontSize="lg"
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../component/Button";
import Checklist from "../assets/icons/67-removebg-preview.png";
import MagnifyingGlass from "../assets/icons/66-removebg-preview.png";
import Stethoscope from "../assets/icons/65-removebg-preview.png";
import Admin from "../assets/icons/68-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AboutChildPdf } from "./AboutChildPdf";
import Header from "../component/Header";

interface ChildInfos {
  child: {
    id: number;
    firstname: string;
    birthdate: string;
    gender: string;
    createdAt: string;
    firstParentId: number;
    secondParentId: number;
    gestionalAgeAtBirth: number;
    image_id: string;
    updatedAt: string;
  };
  size: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
  weight: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
}

const monthDiff = (startDate: Date, endDate: Date) =>
  Math.max(
    0,
    (endDate.getFullYear() - startDate.getFullYear()) * 12 -
      startDate.getMonth() +
      endDate.getMonth()
  );

export const AboutChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [childInfos, setChildInfos] = useState<ChildInfos | null>(null);
  const [childBackgrounds, setChildBackgrounds] = useState<unknown | null>(
    null
  );

  const fetchChildInfos = async () => {
    const fetch = await axios.get(`/children/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      setChildInfos(fetch.data);
    }
  };

  const fetchChildBackgrounds = async () => {
    const fetch = await axios.get(`/children/${id}/all-data`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      setChildBackgrounds(fetch.data);
    }
  };

  useEffect(() => {
    fetchChildInfos();
    fetchChildBackgrounds();
  }, []);

  return (
    <div className="w-screen min-h-screen flex flex-col items-center bg-gradient-to-b from-blue-50 to-blue-100">
      <Header
        title={t("titles.about_child")}
        titleColor="blue"
        childInfo={{
          picture: child.image_id,
          name: child.firstname,
          urlRedirection: `/child-profil/${child.id}`,
        }}
        showBackToMenu={true}
      />

      <section className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-center w-full max-w-6xl px-4 md:px-10 py-6 space-y-6 lg:space-y-0 lg:space-x-12">
        {/* Section d'infos sur l'enfant */}
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start">
          <div className="flex flex-row flex-wrap justify-evenly w-full md:w-4/5 lg:justify-start gap-8 mt-8">
            {[
              {
                label: t("mesure.age"),
                value: `${monthDiff(new Date(childInfos?.child.birthdate || new Date()), new Date())} mois`,
              },
              {
                label: t("mesure.weight"),
                value: `${childInfos?.weight.value} kg`,
              },
              {
                label: t("mesure.height"),
                value: `${childInfos?.size.value} cm`,
              },
            ].map((info, index) => (
              <div
                key={index}
                className="flex flex-col items-center lg:items-start text-center lg:text-left"
              >
                <p className="text-primary font-bold text-lg md:text-xl lg:text-2xl">
                  {info.label}
                </p>
                <span className="text-gray-900 text-md md:text-lg lg:text-xl">
                  {info.value}
                </span>
              </div>
            ))}
          </div>
          <div className="mt-6">
            <PDFDownloadLink
              document={
                <AboutChildPdf
                  childInfos={childInfos}
                  childBackgrounds={childBackgrounds}
                />
              }
              fileName={`je-dis-quoi-au-docteur-${childInfos?.child.firstname}-${new Date().toLocaleDateString()}.pdf`}
            >
              <button className="mt-4 bg-gradient-to-r from-blue-600 to-blue-400 text-white font-semibold text-lg py-3 px-10 rounded-full shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl focus:outline-none focus:ring-4 focus:ring-blue-300">
                {t("titles.Say_doctor")}
              </button>
            </PDFDownloadLink>
          </div>
        </div>

        {/* Section des boutons */}
        <div className="w-full lg:w-1/3 flex flex-col items-center lg:items-stretch space-y-4">
          <ButtonIcon
            text={t("titles.consultation")}
            color="black"
            urlRedirection={`/consultation/${id}`}
            icon={Stethoscope}
            width="full"
            disabled={false}
          />
          <ButtonIcon
            text={t("titles.monitoring")}
            color="black"
            urlRedirection={`/monitoring/${id}`}
            icon={MagnifyingGlass}
            width="full"
            disabled={false}
          />
          <ButtonIcon
            text={t("titles.care_plan")}
            color="black"
            urlRedirection={`/careplan/${id}`}
            icon={Checklist}
            width="full"
            disabled={false}
          />
          <ButtonIcon
            text={t("titles.administrative")}
            color="black"
            urlRedirection="/administratif"
            icon={Admin}
            width="full"
            disabled={false}
          />
        </div>
      </section>
    </div>
  );
};

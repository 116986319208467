/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../component/Table/Table";
import Tabs from "../../component/Tabs/Tabs";
import "./NewChild.scss";
import Carousel from "./components/Carousel";
import { useAuth } from "../../hooks/useAuth";
import axios from "../../lib/config/axios";
import TableCheck from "../../component/TableCheck/TableCheck";
import Header from "../../component/Header";

type GlobalDataType = {
  firstname?: string;
  gender?: "FEMALE" | "MALE";
  birthdate?: Date;
  gestationalAgeAtBirth?: number;
  firstParentId?: number;
  image_id: number;
  size?: number;
  weight?: number;
};

type AntecedentType = {
  id: number;
  date: string;
  name: string;
};

type AllergyType = {
  id: number;
  date: string;
  name: string;
  manifestation: string;
};

// const MATCHING_MANIFESTATION: { [key: string]: string } = {
//   REDNESS: "Rougeur",
//   BUTTONS: "Boutons",
//   SWELLING: "Gonflement",
//   SKIN_ITCH: "Démangeaison",
//   RESPIRATORY_GENE: "Gêne respiratoire",
//   RHINORHEA: "Rhinite",
//   ITCHY_EYES: "Yeux qui piquent",
//   TEARING: "Larmoiement",
//   ETERNALLY: "Éternuements",
//   VOMITING: "Vomissements",
//   DIARRHEA: "Diarrhée",
//   OTHER: "Autre",
// };

const manifestationMatchingList = [
  { id: "REDNESS", name: "Rougeur" },
  { id: "BUTTONS", name: "Boutons" },
  { id: "SWELLING", name: "Gonflement" },
  { id: "SKIN_ITCH", name: "Démangeaison" },
  { id: "RESPIRATORY_GENE", name: "Gêne respiratoire" },
  { id: "RHINORHEA", name: "Rhinite" },
  { id: "ITCHY_EYES", name: "Yeux qui piquent" },
  { id: "TEARING", name: "Larmoiement" },
  { id: "ETERNALLY", name: "Éternuements" },
  { id: "VOMITING", name: "Vomissements" },
  { id: "DIARRHEA", name: "Diarrhée" },
  { id: "OTHER", name: "Autre" },
];

const NewChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("step1");
  const [globalData, setGlobalData] = useState<GlobalDataType>({
    firstname: undefined,
    gender: "FEMALE",
    birthdate: undefined,
    gestationalAgeAtBirth: undefined,
    firstParentId: undefined,
    image_id: 0,
    size: undefined,
    weight: undefined,
  });
  const [allergyList, setAllergyList] = useState<any[]>([]);
  const [medicineList, setMedicineList] = useState<any[]>([]);
  const [vaccinationsList, setVaccinationsList] = useState<any[]>([]);
  const [vacinationData, setVacinationData] = useState<any[]>([]);
  const [allergiesData, setAllergiesData] = useState<any[]>([]);
  const [antecedentsData, setAntecedentsData] = useState<AntecedentType[]>([]);
  const [medicinesData, setMedicinesData] = useState<any[]>([]);
  const [editingAntecedent, setEditingAntecedent] =
    useState<AntecedentType | null>(null);
  const [editingAllergy, setEditingAllergy] = useState<any | null>(null);

  // Function to handle adding a new antecedent
  const handleAddAntecedent = (item: AntecedentType) => {
    setAntecedentsData([...antecedentsData, { ...item, id: Date.now() }]); // Unique ID for tracking
  };
  const handleAddAllergy = (item: AllergyType) => {
    const newAllergy = { ...item, id: Date.now() }; // Générer un ID unique
    setAllergiesData([...allergiesData, newAllergy]);
  };
  // Function to handle starting the edit of an antecedent
  const handleEditAntecedent = (item: AntecedentType) => {
    setEditingAntecedent(item);
  };

  // Update function for inputs in edit form
  const handleInputChange = (key: keyof AntecedentType, value: string) => {
    setEditingAntecedent((prev) => (prev ? { ...prev, [key]: value } : null));
  };

  const handleSelectAllergy = (allergy: any) => {
    setEditingAllergy(allergy);
  };

  // Fonction pour mettre à jour les valeurs du formulaire d'édition de l'allergie
  const handleAllergyInputChange = (
    key: keyof typeof editingAllergy,
    value: string
  ) => {
    setEditingAllergy((prev: any) => (prev ? { ...prev, [key]: value } : null));
  };

  const handleSaveAllergy = () => {
    if (editingAllergy) {
      setAllergiesData((prevData) =>
        prevData.map((allergy) =>
          allergy.id === editingAllergy.id ? { ...editingAllergy } : allergy
        )
      );
      setEditingAllergy(null);
    }
  };

  // Function to save edited antecedent
  const handleSaveAntecedent = () => {
    if (editingAntecedent) {
      setAntecedentsData((prevData) =>
        prevData.map((ant) =>
          ant.id === editingAntecedent.id ? editingAntecedent : ant
        )
      );
      setEditingAntecedent(null); // Clear editing state
    }
  };
  const vacinationColumns = [
    { key: "col1", value: t("new_child.form-data.vacinationColumns.col1") },
    { key: "col2", value: t("new_child.form-data.vacinationColumns.col2") },
    { key: "col3", value: t("new_child.form-data.vacinationColumns.col3") },
    { key: "col4", value: t("new_child.form-data.vacinationColumns.col4") },
    { key: "col5", value: t("new_child.form-data.vacinationColumns.col5") },
    { key: "col6", value: t("new_child.form-data.vacinationColumns.col6") },
    { key: "col7", value: t("new_child.form-data.vacinationColumns.col7") },
    { key: "col8", value: t("new_child.form-data.vacinationColumns.col8") },
    { key: "col9", value: t("new_child.form-data.vacinationColumns.col9") },
    {
      key: "vaccinationDate",
      value: t("new_child.form-data.vacinationColumns.col15"),
    },
  ];

  const vacinationLines = [
    { key: "line1", value: t("new_child.form-data.vacinationLines.line1") },
    { key: "line2", value: t("new_child.form-data.vacinationLines.line2") },
    { key: "line3", value: t("new_child.form-data.vacinationLines.line3") },
    { key: "line4", value: t("new_child.form-data.vacinationLines.line4") },
    { key: "line5", value: t("new_child.form-data.vacinationLines.line5") },
    { key: "line6", value: t("new_child.form-data.vacinationLines.line6") },
    { key: "line7", value: t("new_child.form-data.vacinationLines.line7") },
    { key: "line8", value: t("new_child.form-data.vacinationLines.line8") },
  ];

  const tabs = [
    { label: t("new_child.stepper.step1"), value: "step1" },
    { label: t("new_child.stepper.step2"), value: "step2" },
    { label: t("new_child.stepper.step3"), value: "step3" },
    { label: t("new_child.stepper.step4"), value: "step4" },
    { label: t("new_child.stepper.step5"), value: "step5" },
  ];

  const allergiesColumns = [
    {
      header: t("mesure.date"),
      accessor: "date",
      type: "month",
      component: "input",
    },
    {
      header: t("new_child.stepper.step3"),
      accessor: "name",
      component: "select",
      type: "allergyList",
    },
    {
      header: t("new_child.form_label.manifestation"),
      accessor: "manifestation",
      component: "select",
      type: "manifestationList",
    },
  ];

  const antecedentsColumns = [
    {
      header: t("mesure.date"),
      accessor: "date",
      type: "month",
      component: "input",
    },
    {
      header: t("new_child.stepper.step2"),
      accessor: "name",
      type: "text",
      component: "input",
    },
  ];

  const medicationColumns = [
    {
      header: t("new_child.form_label.medication"),
      accessor: "medication",
      type: "medicineList",
      component: "select",
    },
    {
      header: t("new_child.form_label.period"),
      accessor: "dosage",
      type: "text",
      component: "input",
      placeholder: t("new_child.form-data.placeholder.medicine"),
    },
  ];

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const parent = response.data;
        setGlobalData({ ...globalData, firstParentId: parent.id });
      } catch (err) {
        console.error(err);
      }
    };
    const fetchAllergies = async () => {
      try {
        const response = await axios.get("/allergies", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const allergiesData = response.data;
        setAllergyList(allergiesData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchVaccines = async () => {
      try {
        const response = await axios.get("/vaccinations", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const vaccineData = response.data;
        setVaccinationsList(vaccineData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchMedications = async () => {
      try {
        const response = await axios.get("/medicines", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const medData = response.data;
        setMedicineList(medData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchParentProfile();
    fetchAllergies();
    fetchVaccines();
    fetchMedications();
  }, [user]);

  const handleTabClick = (label: string) => {
    setActiveTab(label);
    const element = document.getElementById(label);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sortedAntecedentsData = [...antecedentsData].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });

  const onChangeGlobalData = (name: string, value: number | string) => {
    setGlobalData({ ...globalData, [name]: value });
  };

  const onChangeOtherData = (
    data: never,
    type: "allergy" | "antecedent" | "medicine"
  ) => {
    if (type == "allergy") {
      setAllergiesData([...allergiesData, data]);
    } else if (type == "antecedent") {
      setAntecedentsData([...antecedentsData, data]);
    } else if (type == "medicine") {
      setMedicinesData([...medicinesData, data]);
    }
  };

  const onValidateForm = (e: any) => {
    e.preventDefault();
    const newMedicinesData = medicinesData.map((med: any) => ({
      ...med,
      dateTime: new Date(Date.now()).toISOString(),
    }));

    const { size, weight, birthdate, ...childData } = globalData;
    const date = birthdate && new Date(birthdate);
    const timestamp = date?.toISOString();

    const modifiedAllergies = allergiesData.map((allergy) => ({
      date: allergy.date,
      allergyId: allergyList.find((a: any) => a.name === allergy.name).id,
      // @ts-expect-error maybe undefined
      manifestation: manifestationMatchingList.find(
        (m: any) => m.name === allergy.manifestation
      ).id,
    }));

    const modifiedVacinations = vacinationData.map((vaccination) => ({
      vaccinationId: vaccination.vaccinationId,
      status: vaccination.status,
      vaccineDate: vaccination.vaccineDate,
    }));

    const formValues = {
      ...childData,
      birthdate: timestamp,
      size,
      weight,
      medicalBackgrounds: antecedentsData,
      vaccinations: modifiedVacinations,
      medicationMonitorings: newMedicinesData,
      allergies: modifiedAllergies,
    };

    axios
      .post(
        "/children/create",
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then(() => {
        window.location.href = "/children";
      });
  };

  return (
    <div className="new-child-container">
      <Header
        title={t("titles.new_child")}
        titleColor="blue"
        showBackToMenu={true}
      />
      <section>
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
        <form onSubmit={onValidateForm}>
          <div id="step1">
            <h2>{t("new_child.stepper.step1")}</h2>
            <Carousel
              active={globalData.image_id}
              onChange={(idx) => onChangeGlobalData("image_id", idx)}
            />
            {/* <div id="name-user">
            </div> */}
            <div id="information-user">
              <div>
                <label>{t("new_child.form_label.firstname")}</label>
                <input
                  value={globalData.firstname}
                  required
                  onChange={(e) =>
                    onChangeGlobalData("firstname", e.target.value)
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.genre")}</label>
                <select
                  value={globalData.gender}
                  onChange={(e) => onChangeGlobalData("gender", e.target.value)}
                  required
                >
                  <option key="FEMALE" value="FEMALE">
                    {t("new_child.form_label.genres.female")}
                  </option>
                  <option key="MALE" value="MALE">
                    {t("new_child.form_label.genres.male")}
                  </option>
                </select>
              </div>
              <div>
                <label>{t("new_child.form_label.birthdate")}</label>
                <input
                  required
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    onChangeGlobalData("birthdate", e.target.value)
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.size")}</label>
                <input
                  type="number"
                  onChange={(e) =>
                    onChangeGlobalData("size", Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.weight")}</label>
                <input
                  type="number"
                  step="0.1"
                  onChange={(e) =>
                    onChangeGlobalData("weight", Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.gestation_time")}</label>
                <input
                  required
                  type="number"
                  onChange={(e) =>
                    onChangeGlobalData(
                      "gestationalAgeAtBirth",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div id="step2">
            <Table
              columns={antecedentsColumns}
              data={sortedAntecedentsData}
              title={t("new_child.stepper.step2")}
              add={(item: AntecedentType) => handleAddAntecedent(item)}
              remove={(updatedData: AntecedentType[]) =>
                setAntecedentsData(updatedData)
              }
              onSelected={(item: AntecedentType) => handleEditAntecedent(item)}
            />
            <div
              className="button-group"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="button-edit"
                onClick={handleSaveAntecedent}
                disabled={!editingAntecedent}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <svg
                  viewBox="0 0 448 512"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "8px",
                  }}
                  className="fill-current text-white"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-242.7c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32L64 32zm0 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 224c-17.7 0-32-14.3-32-32l0-64zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                SAUVEGARDER
              </button>
            </div>
          </div>
          {editingAntecedent && (
            <div className="edit-form">
              {antecedentsColumns.map((col) => (
                <div key={col.accessor}>
                  <label>{col.header}</label>
                  <input
                    type={col.type === "date" ? "date" : "text"}
                    value={
                      editingAntecedent[col.accessor as keyof AntecedentType]
                    }
                    onChange={(e) =>
                      handleInputChange(
                        col.accessor as keyof AntecedentType,
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
            </div>
          )}
          <div id="step3">
            <Table
              columns={allergiesColumns}
              data={allergiesData}
              title={t("new_child.stepper.step3")}
              add={(data: AllergyType) => handleAddAllergy(data)}
              allergyList={allergyList}
              manifestationList={manifestationMatchingList}
              remove={(data: any[]) => setAllergiesData(data)}
              onSelected={(item: any) => handleSelectAllergy(item)}
            />
            <div
              className="button-group"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="button-edit"
                onClick={handleSaveAllergy}
                disabled={!editingAllergy}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <svg
                  viewBox="0 0 448 512"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "8px",
                  }}
                  className="fill-current text-white"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-242.7c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32L64 32zm0 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 224c-17.7 0-32-14.3-32-32l0-64zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                SAUVEGARDER
              </button>
            </div>
          </div>
          {editingAllergy && (
            <div className="edit-form">
              {allergiesColumns.map((col) => (
                <div key={col.accessor}>
                  <label>{col.header}</label>
                  {col.component === "input" ? (
                    <input
                      type={col.type}
                      value={editingAllergy[col.accessor]}
                      onChange={(e) =>
                        handleAllergyInputChange(
                          col.accessor as keyof typeof editingAllergy,
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    <select
                      value={editingAllergy[col.accessor]}
                      onChange={(e) =>
                        handleAllergyInputChange(
                          col.accessor as keyof typeof editingAllergy,
                          e.target.value
                        )
                      }
                    >
                      {(col.type === "allergyList"
                        ? allergyList
                        : manifestationMatchingList
                      ).map((option) => (
                        <option key={option.id} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
          )}
          <div id="step4">
            <h2>{t("new_child.stepper.step4")}</h2>
            <TableCheck
              tableList={vaccinationsList}
              data={vacinationData}
              setData={setVacinationData}
              columns={vacinationColumns}
              lines={vacinationLines}
              columnsName={{
                key: "age",
                title: t("new_child.form_label.age"),
              }}
            />
          </div>
          <div id="step5">
            <Table
              columns={medicationColumns}
              data={medicinesData}
              title={t("new_child.stepper.step5")}
              add={(data: never) => onChangeOtherData(data, "medicine")}
              remove={(data: any[]) => setMedicinesData(data)}
              medicineList={medicineList}
            />
          </div>
          <div className="create-button">
            <button>{t("new_child.create_profile")}</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewChild;

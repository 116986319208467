/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import ProfilePic0 from "../assets/profilPic/0.png";
import ProfilePic1 from "../assets/profilPic/1.png";
import ProfilePic2 from "../assets/profilPic/2.png";
import ProfilePic3 from "../assets/profilPic/3.png";
import ProfilePic4 from "../assets/profilPic/4.png";
import ProfilePic5 from "../assets/profilPic/5.png";
import ProfilePic6 from "../assets/profilPic/6.png";
import ProfilePic7 from "../assets/profilPic/7.png";
import ProfilePic8 from "../assets/profilPic/8.png";
import ProfilePic9 from "../assets/profilPic/9.png";
import ProfilePic10 from "../assets/profilPic/10.png";
import ProfilePic11 from "../assets/profilPic/11.png";
import ProfilePic12 from "../assets/profilPic/12.png";

import MontserratFontRegular from "../assets/fonts/Montserrat/static/Montserrat-Regular.ttf";
import MontserratFontBold from "../assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
import MontserratFontSemibold from "../assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf";
import MontserratFontItalic from "../assets/fonts/Montserrat/static/Montserrat-Italic.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratFontRegular,
    },
    {
      src: MontserratFontBold,
      fontWeight: "bold",
    },
    {
      src: MontserratFontSemibold,
      fontWeight: "semibold",
    },
    {
      src: MontserratFontItalic,
      fontStyle: "italic",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    rowGap: 16,
    paddingHorizontal: 30,
    fontSize: 12,
    fontFamily: "Montserrat",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
  },
  header_section_title: {},
  header_title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FF6B7E",
    marginTop: 10,
  },
  header_infos_section: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 15,
  },
  header_infos: {
    flexDirection: "column",
    rowGap: 15,
  },
  header_section_pp: {
    flexDirection: "column",
    alignItems: "center",
  },
  header_pp: {
    width: 75,
    height: "auto",
    // borderRadius: 50,
  },
  section_title: {
    textDecoration: "underline",
    color: "#FF6B7E",
    fontWeight: "semibold",
    fontSize: 16,
  },
  questions_section: {
    fontWeight: "semibold",
    fontSize: 14,
  },
  questions_section_questions: {
    marginTop: 15,
    flexDirection: "column",
    rowGap: 10,
  },
  background_section: {},
  table: {
    marginTop: 15,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  table_header_row: {
    marginBottom: 10,
    columnGap: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  table_header_cell: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    fontWeight: "bold",
    border: "1px solid #55A47E",
  },
  table_body_row: {
    columnGap: 10,
    flexDirection: "row",
    alignItems: "stretch",
  },
  table_body_cell: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    border: "1px solid #000",
  },
  treatment_section: {},
  allergies_section: {},
  allergies_table: {},
  care_plan_section: {},
  care_plan_table: {},
  title_h1: {},
  title_h2: {},
  text_em: {
    fontWeight: "bold",
    color: "#55A47E",
  },
});

const manifestationMatchingList = {
  REDNESS: "Rougeur",
  BUTTONS: "Boutons",
  SWELLING: "Gonflement",
  SKIN_ITCH: "Démangeaison",
  RESPIRATORY_GENE: "Gêne respiratoire",
  RHINORHEA: "Rhinite",
  ITCHY_EYES: "Yeux qui piquent",
  TEARING: "Larmoiement",
  ETERNALLY: "Éternuements",
  VOMITING: "Vomissements",
  DIARRHEA: "Diarrhée",
  OTHER: "Autre",
};

interface AboutChildPdfProps {
  childInfos: any;
  childBackgrounds: any;
}

const profilesPics = [
  ProfilePic0,
  ProfilePic1,
  ProfilePic2,
  ProfilePic3,
  ProfilePic4,
  ProfilePic5,
  ProfilePic6,
  ProfilePic7,
  ProfilePic8,
  ProfilePic9,
  ProfilePic10,
  ProfilePic11,
  ProfilePic12,
];

const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

// Create Document Component
export const AboutChildPdf = ({
  childInfos,
  childBackgrounds,
}: AboutChildPdfProps) => {
  if (!childInfos || !childBackgrounds) return null;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.header_section_title}>
            <Text style={styles.header_title}>Je dis quoi au docteur ?</Text>
          </View>
          <View style={styles.header_infos_section}>
            <View style={styles.header_infos}>
              <Text>
                <Text style={styles.text_em}>Âge : </Text>
                {getAge(childInfos.child.birthdate)} ans
              </Text>
              <Text>
                <Text style={styles.text_em}>Poids : </Text>
                {childInfos.weight.value} kg
              </Text>
              <Text>
                <Text style={styles.text_em}>Taille : </Text>
                {childInfos.size.value} cm
              </Text>
            </View>
            <View style={styles.header_section_pp}>
              <Image
                src={profilesPics[childInfos.child.image_id]}
                style={styles.header_pp}
              />
              <Text style={{ fontWeight: "bold" }}>
                {childInfos.child.firstname}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.questions_section}>
          <Text style={styles.section_title}>Les questions à vous poser :</Text>
          <View style={styles.questions_section_questions}>
            <Text>Qu'est ce qui vous amène à consulter ?</Text>
            <Text>Depuis quand c'est présent ?</Text>
            <Text>Qu'a-t-on fait pour y remédier ?</Text>
            <Text>Qu'est ce qui nous inquiète ?</Text>
          </View>
        </View>
        <View style={styles.background_section}>
          <Text style={styles.section_title}>Les antécédents :</Text>
          <View style={styles.table}>
            <View style={styles.table_header_row}>
              <Text style={{ ...styles.table_header_cell, width: "20%" }}>
                Date
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "30%" }}>
                Intervention
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "50%" }}>
                Commentaires
              </Text>
            </View>
            {childBackgrounds.medicalBackgrounds.map(
              (bg: any, index: number) => (
                <View style={styles.table_body_row} key={index}>
                  <Text style={{ ...styles.table_body_cell, width: "20%" }}>
                    {new Date(bg.date).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "30%" }}>
                    {bg.name}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "50%" }}>
                    {bg.comment}
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
        <View style={styles.treatment_section}>
          <Text style={styles.section_title}>
            Dernières prises de médicaments :
          </Text>
          <View style={styles.table}>
            <View style={styles.table_header_row}>
              <Text style={{ ...styles.table_header_cell, width: "20%" }}>
                Date
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "50%" }}>
                Médicament
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "30%" }}>
                Dose
              </Text>
            </View>
            {childBackgrounds.medicationMonitorings.map(
              (tr: any, index: number) => (
                <View style={styles.table_body_row} key={index}>
                  <Text style={{ ...styles.table_body_cell, width: "20%" }}>
                    {new Date(tr.date).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "50%" }}>
                    {tr.medecine}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "30%" }}>
                    {tr.dose}
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
        <View style={styles.allergies_section}>
          <Text style={styles.section_title}>Les allergies :</Text>
          <View style={styles.table}>
            <View style={styles.table_header_row}>
              <Text style={{ ...styles.table_header_cell, width: "20%" }}>
                Date
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "30%" }}>
                Allergie
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "50%" }}>
                Symptômes
              </Text>
            </View>
            {childBackgrounds.allergies.map((al: any, index: number) => (
              <View style={styles.table_body_row} key={index}>
                <Text style={{ ...styles.table_body_cell, width: "20%" }}>
                  {new Date(al.date).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </Text>
                <Text style={{ ...styles.table_body_cell, width: "30%" }}>
                  {al.allergy.name}
                </Text>
                <Text style={{ ...styles.table_body_cell, width: "50%" }}>
                  {/* @ts-expect-error maybe something */}
                  {manifestationMatchingList[al.manifestation]}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

import React, { useState } from "react";
import { ButtonText } from "../component/Button";
import { useTranslation } from "react-i18next";
import Header from "../component/Header";

export const Administratif = () => {
  const { t } = useTranslation();
  const [showGenAuto, setShowGenAuto] = useState(false);
  const [showGenCertif, setShowGenCertif] = useState(false);

  const handleShowGenAuto = () => {
    setShowGenAuto(true);
  };
  const handleShowGenCertif = () => {
    setShowGenCertif(true);
  };
  const genAuto = () => {
    setShowGenAuto(false);
  };
  const genCertif = () => {
    setShowGenCertif(false);
  };

  return (
    <div className="w-screen">
      <Header
        title={t("titles.administrative")}
        titleColor="green"
        subtitle={t("administrative.parental_authorization")}
        showBackToMenu={true}
      />
      <section className="flex flex-col justify-between px-12 w-full">
        <div className="flex flex-col justify-start">
          <h2 className="block md:my-8 my-4 md:text-2xl text-md font-semibold underline text-gray-900 ">
            {t("administrative.parental_authorization")}
          </h2>
          <div className="flex flex-row gap-4">
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.care_auto")}
              onclick={handleShowGenAuto}
            />
          </div>
          <div>
            {showGenAuto && (
              <button
                type="submit"
                onClick={genAuto}
                className="flex items-center mt-8 py-2 px-4 text-sm bg-primary text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-blue-600"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 h-6"
                >
                  <path
                    d="M12 6V18"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                  <path
                    d="M18 12H6"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                </svg>
                {t("buttons.generate")}
              </button>
            )}
          </div>
          <h2 className="block md:my-8 my-4 md:text-2xl text-md font-semibold underline text-gray-900 ">
            {t("administrative.medical_certificate")}
          </h2>
          <div className="md:flex md:flex-row md:gap-4 space-y-4">
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.sick_day")}
              onclick={handleShowGenCertif}
            />
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.sports_exemption")}
              onclick={handleShowGenCertif}
            />
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.school_exclusion")}
              onclick={handleShowGenCertif}
            />
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.PAI")}
              onclick={handleShowGenCertif}
            />
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.other")}
              onclick={handleShowGenCertif}
            />
          </div>
          {showGenCertif && (
            <div>
              <button
                type="submit"
                onClick={genCertif}
                className="flex items-center mt-8 py-2 px-4 text-sm bg-primary text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-blue-600"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 h-6"
                >
                  <path
                    d="M12 6V18"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                  <path
                    d="M18 12H6"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                </svg>
                {t("buttons.generate")}
              </button>
            </div>
          )}
          <h2 className="block md:my-8 my-4 md:text-2xl text-md font-semibold underline text-gray-900 ">
            {t("administrative.download_prescription")}
          </h2>
          <div className="flex flex-row gap-4">
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.usual")}
              onclick={handleShowGenCertif}
            />
            <ButtonText
              color="green-500"
              text={t("administrative.button_text.punctual")}
              onclick={handleShowGenCertif}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import "./TableCheck.scss";
import CheckSVG from "../../assets/icons/Check";
import PendingSVG from "../../assets/icons/Pending";

interface TableCheckProps {
  tableList: any[];
  data: any[];
  setData: any;
  columns: { key: string; value: string }[];
  lines: { key: string; value: string }[];
  columnsName: { key: string; title: string };
}

const TableCheck: React.FC<TableCheckProps> = ({
  tableList,
  data,
  setData,
  columns,
  lines,
  columnsName,
}) => {
  const [cellStatus, setCellStatus] = useState<{
    [key: string]: "Completed" | "Pending" | "";
  }>({});
  const [selectedVaccin, setSelectedVaccin] = useState<any>(null);
  const [vaccinDate, setVaccinDate] = useState("");

  useEffect(() => {
    const initialStatus: { [key: string]: "Completed" | "Pending" | "" } = {};
    tableList.forEach((d) => {
      const key = `${d.columnsName}`;
      initialStatus[key] = "Pending";
    });
    setCellStatus(initialStatus);
  }, [tableList]);

  const handleToggle = (line: string, col: string) => {
    const key = `${line}-${col}`;
    const idxExist = data.findIndex((d) => d.columnsName === key);

    if (idxExist > -1) {
      setSelectedVaccin({
        key,
        title: col,
        age: line,
        date: data[idxExist].vaccineDate,
      });
      setVaccinDate(data[idxExist].vaccineDate || "");
    } else {
      const vaccin = tableList.find((t) => t.columnsName === key);
      setSelectedVaccin({ key, title: vaccin?.title || col, age: line });
      setVaccinDate("");
    }
  };

  const handleSaveDate = () => {
    const formattedKey = `${selectedVaccin.age}-vaccinationDate`;

    const item = {
      vaccinationId: tableList.find((t) => t.columnsName === selectedVaccin.key)
        ?.id,
      columnsName: formattedKey,
      status: "DONE",
      vaccineDate: vaccinDate,
    };
    setData(
      (
        prevData: {
          vaccinationId: any;
          columnsName: string;
          status: string;
          vaccineDate: string;
        }[]
      ) => {
        const idx = prevData.findIndex((d) => d.columnsName === formattedKey);
        if (idx > -1) {
          prevData[idx] = item;
        } else {
          prevData.push(item);
        }
        return [...prevData];
      }
    );

    setCellStatus((prev) => ({
      ...prev,
      [selectedVaccin.key]: "Completed",
    }));
    setSelectedVaccin(null);
  };

  const handleDeleteDate = () => {
    const formattedKey = `${selectedVaccin.age}-vaccinationDate`;
    setData((prevData: any[]) => {
      const updatedData = prevData.filter(
        (d) => d.columnsName !== formattedKey
      );
      return updatedData;
    });
    setCellStatus((prev) => ({
      ...prev,
      [selectedVaccin.key]: "Pending",
    }));
    setSelectedVaccin(null);
  };

  const renderCell = (line: string, col: string) => {
    const key = `${line}-${col}`;
    const isDateColumn = col === "vaccinationDate";
    const cellData = data.filter((item) => item.columnsName === key);

    if (isDateColumn && cellData.length > 0) {
      const formattedDates = cellData
        .map((item) =>
          new Date(item.vaccineDate).toLocaleDateString("fr-FR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        )
        .join("\n");

      return (
        <td key={key} className="cell cell-with-date">
          <span className="formatted-date">{formattedDates}</span>
        </td>
      );
    }

    const iconByStatus = {
      Completed: <CheckSVG />,
      Pending: <PendingSVG />,
      "": "",
    };
    return (
      <td
        key={key}
        className={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? "cell"
            : "cell-disabled"
        }
        onClick={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? () => handleToggle(line, col)
            : () => ({})
        }
      >
        {iconByStatus[cellStatus[key]]}
      </td>
    );
  };

  return (
    <div className="table-div">
      <table className="check-table">
        <thead>
          <tr>
            <th>{columnsName.title}</th>
            {columns.map((col) => (
              <th key={col.key}>{col.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lines.map((line) => (
            <tr key={line.key}>
              <td>{line.value}</td>
              {columns.map((col) => renderCell(line.key, col.key))}
            </tr>
          ))}
        </tbody>
      </table>
      {selectedVaccin && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <label className="block text-sm font-medium">
              Date de ce vaccin :
            </label>
            <input
              type="date"
              value={vaccinDate}
              onChange={(e) => setVaccinDate(e.target.value)}
              className="border border-gray-300 rounded-md p-2 mt-2 w-full"
            />
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setSelectedVaccin(null)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
              >
                Annuler
              </button>
              <button
                onClick={async () => {
                  handleDeleteDate();
                  await fetch("/api/delete-vaccin", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ columnsName: selectedVaccin.key }),
                  });
                }}
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
              >
                Supprimer
              </button>
              <button
                onClick={async () => {
                  handleSaveDate();
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCheck;

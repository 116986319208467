/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Select from "react-select";
import { FaPills, FaClock, FaSave, FaCalendarAlt } from "react-icons/fa";
import { CardMonitoring } from "../component/Cards";

const frequencyOptions = [
  { value: "daily", label: "Tous les jours" },
  { value: "weekly", label: "Toutes les semaines" },
  { value: "custom-day", label: "Chaque n*jours" },
  { value: "custom-week", label: "Chaque n*semaines" },
];

const daysOptions = [
  { value: "lundi", label: "Lundi" },
  { value: "mardi", label: "Mardi" },
  { value: "mercredi", label: "Mercredi" },
  { value: "jeudi", label: "Jeudi" },
  { value: "vendredi", label: "Vendredi" },
  { value: "samedi", label: "Samedi" },
  { value: "dimanche", label: "Dimanche" },
];

const Prescription: React.FC<{ onSave: (data: unknown) => void }> = ({
  onSave,
}) => {
  const [medication, setMedication] = useState("");
  const [dosage, setDosage] = useState("");
  const [frequency, setFrequency] = useState("");
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCard, setShowCard] = useState(false);

  const handleSave = () => {
    const prescriptionData = {
      medication,
      dosage,
      frequency,
      selectedDays,
      startDate,
      endDate,
    };
    onSave(prescriptionData);
    setShowCard(true);
  };

  return (
    <div className="prescription-container p-6 bg-green-50 rounded-lg shadow-md mb-6 w-full">
      <h2 className="text-2xl font-semibold text-green-600 mb-6">Ordonnance</h2>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaPills className="mr-2" /> Nom du médicament
        </label>
        <input
          type="text"
          value={medication}
          onChange={(e) => setMedication(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
          placeholder="Ex: Paracétamol"
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaPills className="mr-2" /> Dosage
        </label>
        <input
          type="text"
          value={dosage}
          onChange={(e) => setDosage(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
          placeholder="Ex: 500mg"
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaClock className="mr-2" /> Fréquence
        </label>
        <Select
          options={frequencyOptions}
          value={frequencyOptions.find((opt) => opt.value === frequency)}
          onChange={(opt) => setFrequency(opt?.value || "")}
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaCalendarAlt className="mr-2" /> Date de début
        </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
        />
      </div>

      <div className="mb-6">
        <label className="flex items-center font-semibold mb-2 text-green-600">
          <FaCalendarAlt className="mr-2" /> Date de fin
        </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="w-full p-3 border-2 border-green-300 rounded-lg"
        />
      </div>

      <button
        onClick={handleSave}
        className="py-2 px-4 bg-green-600 text-white rounded-lg font-semibold shadow-md hover:bg-green-700 flex items-center mx-auto focus:outline-none focus:ring-2 focus:ring-green-300 mb-6"
      >
        <FaSave /> Sauvegarder
      </button>

      {showCard && (
        <CardMonitoring
          time={new Date().toLocaleDateString()}
          icone="prescription"
          title={medication}
          description={`${dosage} - ${frequency}`}
          isWarning={false}
        />
      )}
    </div>
  );
};

export default Prescription;
